import React, { Fragment, useEffect, useState } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import {Link} from 'react-router-dom'
import styles from './View.Style'
import Button from 'components_newui/button/View'
// import Idea_Panel from '../../components_newui/idea_panel/View'
// import Challenge_Card from '../../components_newui/challenge_card/View'
import ChallengeCarousel from '../../components_newui/challenge_carousel/View'
import RecommendedIdeas from '../../components_newui/recommended_ideas/View'
import no_draft_ideas_img from '../../img/no_draft_idea.png'
import hero_img from 'img/hero_image.png'
import chevron from 'img/chevron-up-purple.png'
// import { CHALLENGES_PAGE } from '../../common_constants/Pages'
import Contributor from './components/contributor/View'
import contributor_bg from 'img/champion_bg.png'
import contributor_types from './contributor_type'
import Loader from 'support/loader/nxt_loader'
import { Container } from '@material-ui/core'

import myIdeaIcon from 'img/my-idea.png'
import myChallengeTagIcon from 'img/challenge-tag.png'
import ideaInvestIcon from 'img/idea-invest.png'
import myChallengeIcon from 'img/my-challenge.png'
// import { AllIdeas_path } from 'support/navigation/linkRoutePath'
import * as pages from 'common_constants/Pages'
import NavigationService from 'support/navigation/NavigationService'



const New_Explore = props => {

const ROUTES = NavigationService.getRoutes()
const AllIdeas_path =  ROUTES[pages.ALL_IDEAS].path
const AllChallenges_path =  ROUTES[pages.CHALLENGES_PAGE].path
const NEW_CHALLENGE_path =  ROUTES[pages.NEW_CHALLENGE].path
const PROPOSE_CHALLENGE_path =  ROUTES[pages.PROPOSE_CHALLENGE].path
const RANKING_PAGE_path =  ROUTES[pages.RANKING_PAGE].path


    const {
        classes,
        domainLoaded,
        ideas,
        challenges,
        navigateToIdea,
        navigateToProposeChallenge,
        navigateToNewChallenge,
        submitYourIdea,
        footerInvestors,
        footerEntrepreneurs,
        navigateToRanking,
        navigateToAllIdeas,
        navigateToChallenges,
        navigateToChallengeDetail,
        userIdeas,
        investments,
        userEmail,
        isIdeasLoading,
        navigateToInvestments,navigateToMyInvestedIdea,
        isAdmin, isInnovator, isOwner, localAdmin, localInnovator,
        canProposeChallenge,
    } = props

    const [apiTest, setApiTest] = useState();


    useEffect(() => {
        const {
            getActiveChallenges,
            getIdeas,
            getRankings,
            getMyIdeas,
            getInvestments
        } = props
        if (domainLoaded) {
            getActiveChallenges()
            getIdeas()
            getRankings()
            getMyIdeas()
            getInvestments(userEmail)
        }
    }, [domainLoaded])

  
    const challenge_ideas = userIdeas.filter(idea => idea.challenged === true)





    const HeroSectionComponents = ()=>{
        return(
            <div className={classes.heroContainer}>
                <img className={classes.heroImg} src={hero_img} />
                <div className={classes.heroContent}>
                    <div className={classes.exploreheading}>Shape the Future of Vodafone</div>
                    <div className={classes.exploresubheading} >Had a great idea? Let the NOVA community know about it</div>
                    <button onClick={submitYourIdea} className={classes.postIdea}>Submit an Idea</button>
                </div>
            </div>
        )
    }


    const LatestIdeaComponents = () => {
        return( 
            ideas && ideas.length > 0 ? 
            <>
                <RecommendedIdeas bg_color={'#fbfaff'} recommendedIdeas={ideas} />
                <div style={{ display: 'flex', justifyContent: 'center', marginBottom:'3rem' }}>
                    <Link to={`${AllIdeas_path}`} className={classes.standard_button}>
                        View all
                    </Link>
                </div>
            </>
                : null

        )
    }

    const LatestChallengeComponents = () =>{
        return(
             challenges && challenges.length > 0 ? <> 
                <div className={classes.generic_holder} >
                    <div className={classes.title_text}>Challenges</div>
                    <div className={classes.subtitle_text} style={{ marginBottom: '32px' }}>
                        Step up to the challenge and impact Vodafone’s future
                    </div>

                    <ChallengeCarousel  challenges={challenges}  navigateToChallengeDetail={navigateToChallengeDetail} />
                   <div className={classes.alignbtn}>
                        <Link to={`${AllChallenges_path}`} className={classes.standard_button}>
                                View all
                            </Link>
                        {/* <button type='button' onClick={navigateToChallenges} className={classes.standard_button}>View all</button> */}
                   </div>
                </div>  </> : null 
        )
    }


    const GerericIdeaChallegeComponents = () =>{
        return(
            <div className={classes.generic_list}>
                <div className={classes.info_card}>
                    <div className={classes.info_card_bulk}>
                        <div style={{ borderRight: '1px solid #CCCCCC' }} className={classes.infoCardSection}>
                            <div className='countHeading'>
                                <div className={classes.exploreIcons}>
                                    <img src={myIdeaIcon} className={classes.countIcon} alt='icon' />
                                </div>
                                <h3> My Ideas</h3>
                            </div>
                            <div className={classes.number_info}>{userIdeas.length}</div>
                        </div>
                        <div className={classes.infoCardSection}>
                            <div className='countHeading'>
                                <div className={classes.exploreIcons}>
                                    <img src={myChallengeIcon} className={classes.countIcon} alt='icon' />
                                </div>
                                <h3> Active Challenges </h3>
                            </div>
                            <div className={classes.number_info}>{challenges.length}</div>
                        </div>
                    </div>
                    <div className={classes.info_card_footer}>
                        <div className={classes.info_card_footer_section}>
                            {/* <span onClick={submitYourIdea} style={{ cursor: 'pointer' }} className={classes.info_card_footer_subsection}> */}
                           {/* <Link to={`${}`} className={classes.btnTrasparents}>
                                Post an idea <img src={chevron} className={classes.chevron_right} />
                           </Link> */}
                            <button onClick={submitYourIdea} className={classes.btnTrasparents} >
                                Post an idea <img src={chevron} className={classes.chevron_right} />
                            </button>
                            {/* </span> */}
                        </div>
                        <div className={classes.info_card_footer_section}>
                            {
                                Boolean(isAdmin || isInnovator || localAdmin) && (
                                <Link to={`${NEW_CHALLENGE_path}`} className={classes.btnTrasparents} >
                                    Create challenge <img src={chevron} className={classes.chevron_right} alt='img' />
                                </Link>)
                            }

                            {
                                Boolean(canProposeChallenge) &&
                                <Link onClick={`${PROPOSE_CHALLENGE_path}`} className={classes.btnTrasparents} >
                                    Propose a challenge <img src={chevron} className={classes.chevron_right} alt='icon' />
                                </Link>
                            }
                                  
                        </div>
                    </div>
                </div>
                <div className={classes.info_card}>
                    <div className={classes.info_card_bulk}>
                        <div style={{ borderRight: '1px solid #CCCCCC' }} className={classes.infoCardSection}>
                            <div className='countHeading'>
                                <div className={classes.exploreIcons}>
                                    <img src={ideaInvestIcon} className={classes.countIcon} alt='icon' />
                                </div>
                                <h3> Ideas <p>(I have invested in)</p></h3>
                            </div>
                            <div className={classes.number_info}>{investments.length}</div>

                        </div>
                        <div className={classes.infoCardSection}>
                            <div className='countHeading'>
                                <div className={classes.exploreIcons}>
                                    <img src={myChallengeTagIcon} className={classes.countIcon} alt='icon' />
                                </div>
                                <h3> Challenges <p>(I have tagged my ideas to)</p></h3>
                            </div>
                            <div className={classes.number_info}>{challenge_ideas.length}</div>
                        </div>
                    </div>
                    <div className={classes.info_card_footer}>
                        <div className={classes.info_card_footer_section}>
                            {/* <span onClick={navigateToAllIdeas} style={{ cursor: 'pointer' }} className={classes.info_card_footer_subsection}> */}
                            {isInnovator || isAdmin}
                            <Link  to={`${AllIdeas_path}`} className={classes.btnTrasparents} >
                                Explore more ideas <img src={chevron} className={classes.chevron_right} alt='icon' />
                            </Link>
                            {/* </span> */}
                        </div>
                        <div className={classes.info_card_footer_section}>
                            {/* <span onClick={navigateToChallenges} style={{ cursor: 'pointer' }} className={classes.info_card_footer_subsection}> */}
                            <Link to={`${AllChallenges_path}`} className={classes.btnTrasparents} >
                                Explore challenges <img src={chevron} className={classes.chevron_right} alt="icon" />
                            </Link>
                            {/* </span> */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }



    return (
        <Fragment>

            <main className={classes.background}>
               <HeroSectionComponents />
                <Container>
                    <LatestIdeaComponents />    
                    <LatestChallengeComponents />
                    <GerericIdeaChallegeComponents />             
                </Container>
                <div className={classes.contributors}>
                    {/* <Container> */}
                        <img src={contributor_bg} className={classes.contributors_bg} />
                        <div style={{ fontSize: '40px', marginTop: '48px' }}>Top Innovators</div>
                        <div className={classes.subtitle_text} style={{ marginBottom: '47px' }}>
                            NOVA would not be possible without our fantastic community, our most active innovators are:
                        </div>
                        <div className={classes.contributors_container}>
                            {footerInvestors.sort((a, b) => b.amountInvested - a.amountInvested).slice(0, 2)
                                .map(investor => <Contributor
                                    contributor={investor}
                                    type={contributor_types.INVESTOR} />)}
                            {footerEntrepreneurs.sort((a, b) => b.ideas - a.ideas).slice(0, 2)
                                .map(ideator => <Contributor
                                    contributor={ideator}
                                    type={contributor_types.IDEATOR} />)}
                        </div>
                        <Link style={{ zIndex: 10 }} className={classes.standard_button} to={`${RANKING_PAGE_path}`}>View details</Link>
                    {/* </Container> */}
                </div>

            </main>


            {/* <button style={{background:'red',padding:'8px',margin:'8px'}} onClick={CountryApiTest}>Click Country API</button>
                <button style={{background:'red',padding:'8px',margin:'8px'}} onClick={CountryINApiTest}>Click Country API With IN</button>
                <button style={{background:'red',padding:'8px',margin:'8px'}} onClick={ApiTest}>Click API Test</button> */}


        </Fragment>
    )
}

export default withStyles(styles)(New_Explore)