import React, { Component, Fragment, useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import * as PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import defaultLogo from 'img/logo.png'
import i18n from 'support/i18n'
import ButtonLink from 'components/button_link/View'
import styles from './View.Style'
import { FullPageDialog } from 'components'
import FooterNavigationLink from '../FooterNavigationLink/View'
import * as pages from 'common_constants/Pages'
import NavigationService from 'support/navigation/NavigationService'


const FooterNew = (props)=>{

  const ROUTES = NavigationService.getRoutes()
  const MyAllIdeas_path =  ROUTES[pages.MY_IDEAS_PAGE].path
  const AllIdeas_path =  ROUTES[pages.ALL_IDEAS].path
  const AllInvestedIdeas_path =  ROUTES[pages.MY_INVESTED_IDEAS].path
  const ABOUT_US_path =  ROUTES[pages.ABOUT_US].path
  const NOVA_RESEARCH_path =  ROUTES[pages.NOVA_RESEARCH].path
  const CONTACTUS_path =  ROUTES[pages.CONTACTUS].path
  const CASESTUDY_path =  ROUTES[pages.CASESTUDY].path
  const CHALLENGES_PAGE_path =  ROUTES[pages.CHALLENGES_PAGE].path
  const NEW_CHALLENGE_path =  ROUTES[pages.NEW_CHALLENGE].path
  const PROPOSE_CHALLENGE_path =  ROUTES[pages.PROPOSE_CHALLENGE].path
  
  


  const {
    appVersion,
    classes,
    copyrightYear,
    newIdeas,
    logoUrl,
    showAdmin,
    totalInvestments,
    customFooterText,
    onCopyrightClick,
    onLogoClick,
    domains,
    domain,
    navigateToAllIdeas,
    navigateToMyIdeas,
    navigateToMyChallenge,
    navigateToInvestments,
    navigateToChallenges,
    navigateToNewChallenge,
    navigateToProposeChallenge,
    navigateToAboutus,
    navigateToNovaResearch,
    navigateToNovaContact,
    navigateToMyInvestedIdeas,
    isAdmin,isOwner,localAdmin,
    isInnovator,
    canProposeChallenge,
  } = props

  const [showInfo,setShowInfo]=useState({
    title:'',
    showpc:false,
    showln:false
  })

   const FooterLinkIdeaArr = [
    {
      id:1,
      path:MyAllIdeas_path,
      title:'My Ideas'
    },
    {
      id:2,
      path:AllIdeas_path,
      title:'All Ideas'
    },
    {
      id:3,
      path:AllInvestedIdeas_path,
      title:'My Invested Ideas'
    },
   ]


   const FooterLinkHelpArr = [
    {
      id:1,
      path:ABOUT_US_path,
      title:'About NOVA'
    },
    {
      id:2,
      path:CASESTUDY_path,
      title:'Case Studies'
    },
    {
      id:3,
      path:NOVA_RESEARCH_path,
      title:'NOVA Research'
    },
    {
      id:4,
      path:CONTACTUS_path,
      title:'Contact Us'
    },
   ]



   // TO-DO: Navigate to the specific domain
  const navToAdmin = () => {
    window.location.replace(process.env.REACT_APP_ADMIN_BASE_URL)
    return null
  }
const showPrivacyCookies = () => {
  setShowInfo({ ...showInfo,title: 'Privacy and Cookies', showpc: true, showln: false })
}

const showLegalNotice = () => {  
  setShowInfo({ ...showInfo, title: 'Legal Notice', showpc: false, showln: true })
}

const hideTnC = () => {
  setShowInfo({ ...showInfo,showpc: false, showln: false })
}

const {title,showln,showpc} = showInfo
  return(
    <>
    <Fragment>
        <div className={classes.footer}>
          <div className={classes.footerContent}>
            <div className={classes.summary}>
              <div className={classes.summaryIcon}>
                <b className="icon-idea" />
                <span>{newIdeas}</span>
              </div>
              <span className={classes.summaryText}>{i18n.t('IDEAS_PROPOSED')}</span>
            </div>

            <div className={classes.summary}>
              <div className={classes.summaryIcon}>
                <b className="icon-ideas-implemented" />
                <span>{totalInvestments}</span>
              </div>
              <span className={classes.summaryText}>{i18n.t('IDEAS_INVESTED')}</span>
            </div>

          </div>
        </div>

        <div className={classes.footerNavigationLink}>
          <div className={classes.flexForLinks}>
            <h4>Idea</h4>
              {
                FooterLinkIdeaArr.map((item)=>{
                  return(
                    <Link key={item.id} to={item.path}  className={classes.copyrightButton}>
                      {item.title}
                    </Link>
                  )
                })
              }
          </div>
          <div className={classes.flexForLinks}>
            <h4>Challenge</h4>
            <Link to={CHALLENGES_PAGE_path}  className={classes.copyrightButton}> All Challenge</Link>
                      {/* {
              (isAdmin || isOwner || localAdmin) ? 
              ( <Link to={NEW_CHALLENGE_path}  className={classes.copyrightButton}>New Challenge</Link> ) : 
              ( <Link to={PROPOSE_CHALLENGE_path}  className={classes.copyrightButton}>Propose Challenge</Link> )
            } */}

            { Boolean(isAdmin || isInnovator  ) &&  <Link to={NEW_CHALLENGE_path}  className={classes.copyrightButton}>New Challenge</Link> }
             { Boolean(canProposeChallenge) &&  <Link to={PROPOSE_CHALLENGE_path}  className={classes.copyrightButton}>Propose Challenge</Link> }
            

          </div>
          <div className={classes.flexForLinks}>
            <h4>Help</h4>
            {
                FooterLinkHelpArr.map((item)=>{
                  return(
                    <Link key={item.id} to={item.path}  className={classes.copyrightButton}>
                      {item.title}
                    </Link>
                  )
                })
              }
          </div>
          {/* <div className={classes.flexForLinks}>
            <h4>Quick Links</h4>
            <FooterNavigationLink
              children="Contact Us"
              //onLinkClick={navigateToNovaResearch}
            />
            <FooterNavigationLink
              children="Case Studies"
              //onLinkClick={navigateToNovaResearch}
            />
            <p></p>
          </div> */}
          <div className={classes.footerLogo}>
            <img
              alt="Nova"
              width="200"
              src={logoUrl ? logoUrl : defaultLogo}
              style={{marginBottom:'8px'}}
            />
            {i18n.t('FOOTER.COPYRIGHT').replace('##YEAR##', copyrightYear)}
          </div>
        </div>

        {/* <div className="flexForLinks footerLegalAndPrivacy">
          <FooterNavigationLink
            children={i18n.t('FOOTER.COOKIES')}
            onLinkClick={navigateToNovaResearch}

          />
          <FooterNavigationLink
            children={i18n.t('FOOTER.LEGAL')}
            onLinkClick={navigateToNovaResearch}
          />
        </div> */}
        <div className={classes.links}>
          <div className={classes.linksContent}>
            
            <ButtonLink
              classes={{ linkButton: classes.copyrightButton }}
              onLinkClick={() => onCopyrightClick(copyrightYear)}
            >
              {i18n.t('FOOTER.COPYRIGHT').replace('##YEAR##', copyrightYear)}
            </ButtonLink>

            <ButtonLink
              classes={{ linkButton: classes.copyrightButton }}
              onLinkClick={showPrivacyCookies}
            >
              {i18n.t('FOOTER.COOKIES')}
            </ButtonLink>


            <ButtonLink
              classes={{ linkButton: classes.copyrightButton }}
              onLinkClick={showLegalNotice}
            >
              {i18n.t('FOOTER.LEGAL')}
            </ButtonLink>


            <span>{appVersion}</span>
          </div>
          {(showpc || showln) && (
            <FullPageDialog
              openDialog={true}
              closeDialog={hideTnC}
              title={title}
              privacyCookies={showpc}
              legalNotice={showln}
            />
          )}
        </div>
        {customFooterText && (
          // <div className={classes.customizedMessageFooter}>{customFooterText}</div>
          <div className={classes.customFooter}>
            <span dangerouslySetInnerHTML={{ __html: customFooterText }} />
          </div>
        )}
      </Fragment>
    
    </>
  )
}


FooterNew.defaultProps = {
  customFooterText: '',
  logoUrl: '',
}

FooterNew.propTypes = {
  classes: PropTypes.shape({
    appLogo: PropTypes.string.isRequired,
    button: PropTypes.string.isRequired,
    footer: PropTypes.string.isRequired,
    footerContent: PropTypes.string.isRequired,
    summary: PropTypes.string.isRequired,
    summaryIcon: PropTypes.string.isRequired,
    summaryText: PropTypes.string.isRequired,
    links: PropTypes.string.isRequired,
    linksContent: PropTypes.string.isRequired,
    customizedMessageFooter: PropTypes.string.isRequired,
  }).isRequired,
  appVersion: PropTypes.string.isRequired,
  newIdeas: PropTypes.string.isRequired,
  logoUrl: PropTypes.string,
  showAdmin: PropTypes.bool.isRequired,
  onLogoClick: PropTypes.func.isRequired,
  onCopyrightClick: PropTypes.func.isRequired,
  //submitYourIdea: PropTypes.func.isRequired,
  totalInvestments: PropTypes.string.isRequired,
  customFooterText: PropTypes.string,
  copyrightYear: PropTypes.string.isRequired,
}

export default withStyles(styles)(FooterNew)
